.list-compensationBox-main {
    padding: 40px;
}

.list-compensationBox-main-button {
    text-align: end;
}

.list-compensationBox-button-title {
    min-width: 150px;
}

 /* Large devices (desktops, 992px and up) */
@media (max-width: 992px) { 
    .list-compensationBox-main {
        padding: 20px;
    }
}