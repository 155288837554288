.login-card-container {
    margin-top: 5%;
}

.login-container-flex {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 500px;
}

.login-container-form-one {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 50%;
    min-height: 400px;
    background: #DA3434;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
    padding: 20px;
}

.login-container-form-two {
    width: 50%;
    min-height: 400px;
    background: #FFF;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.login-container-card-two {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    position: relative;
}

.login-form-one-title {
    color: #FFF;
    text-align: center;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.login-form-two-title {
    color: #DA3434;
    text-align: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.login-btn-login {
    width: 100%;
    padding: 10px;
    border-radius: 0px;
    font-weight: 700;
    letter-spacing: 1px;
}

.login-title-login {
    cursor: pointer;
    font-weight: 700;
    color: #DA3434;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.login-center-title {
    text-align: center;
}

.login-input {
    background: #FFFCFC;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 10px;
    font-weight: 600;
    font-family: 'Poppins';
}

.login-input::placeholder {
    color: #BCB7B7;
    font-size: 18px;
    font-weight: 600;
}

.login-position-img-van {
    position: absolute;
    text-align: end;
    z-index: 1;
}

.login-img-van {
    width: 200px;
    position: relative;
    left: 40px;
    bottom: 30px;
}

.login-position-img-line {
    position: absolute;
}

.login-img-line {
    width: 100%;
    height: 400px;
}

.center-text {
    text-align: center;
 }


/* parte movil */

 /* Small devices (landscape phones, 576px and up) */
@media (max-width: 992px) { 

   .login-mobile-container-card {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #060424;
        width: 100%;
        height: 100%;
   }

   .login-mobile-card {
        border-radius: 20px;
        background: #FFF;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        padding: 40px;
   }

   .login-mobile-text{
        color: #F00;
        font-size: 20px;
        font-weight: 700;
   }

   .login-mobile-input {
        border-radius: 30px;
        padding: 15px 12px 15px 30px;
        background: #FFFCFC;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
        font-weight: 600;
        font-family: 'Poppins';
   }

   .login-mobile-input::placeholder {
        color: #BCB7B7;
        font-size: 18px;
        font-weight: 600;
    }

    .login-mobile-button {
        width: 100%;
        font-weight: 700;
        letter-spacing: 1px;
        border-radius: 30px;
        padding: 15px 12px 15px 12px;
    }

}


/* aqui finaliza la movil */