.home-contenedor {
    display:flex;
    min-height:100%;
}

.home-fila0 { 
    background:#FFFFFF; 
    width: 15%;
    padding: 40px;
    min-width: 350px;
}

.home-fila1 { 
    width: 85%;
    background:#D9D9D9;
    display: flex;
    flex-direction: column;
}

.home-close-img {
  cursor: pointer;
}

/* Sidebar */

.home-sidebar-container-logo {
    text-align: center;
}

.home-sidebar-logo {
    height: 70px;
}

.home-sidebar-container-links {
  margin-top: 60px;    
}

.home-sidebar-links {
    text-align: initial;
    width: 100%;
    padding: 15px;
    background-color:#ffffff; 
    color: #1E88E5;
    font-size: 18px;
    font-weight: 700;
    cursor: pointer;
    border: none;
    outline: none !important;
  }

  .home-sidebar-links-show {
    background-color:#1E88E5;
    color: #ffffff;   
    font-size: 18px;
    font-weight: 700;
  }

  .home-sidebar-links:hover {
    background-color:#1E88E5;
    color: #ffffff;   
    font-size: 18px;
    font-weight: 700;
  }

  .home-sidebar-icon {
    margin-right: 5px;
    margin-bottom: 5px;
    width: 20px;
  }

  .home-sidebar-icon-show {
    filter: invert(100%) sepia(52%) saturate(2%) hue-rotate(262deg) brightness(170%) contrast(101%);
  }

  .home-sidebar-links:hover .home-sidebar-icon {
    filter: invert(100%) sepia(52%) saturate(2%) hue-rotate(262deg) brightness(170%) contrast(101%);
  } 
  
/* Aplicacion movil */

@media (max-width: 1200px) { 
  
  .home-mobile-container {
    background-color: #D9D9D9;
    width: 100%;
    /* height: 90%; */
  }

  .home-sidebar-container-close-img {
    text-align: end;
    padding: 0px 0px 20px 0px;
  }

  .home-sidebar-links {
    padding: 10px;
  }

  /* sidebar */

.home-offcanvas-modal {
  position: fixed;
  z-index: 100;
  top: 0;
  left: -300px; /* Cambia el valor para ajustar la posición inicial */
  width: 300px;
  height: 100%;
  background-color: #fff;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2);
  transition: left 0.3s ease-in-out;
  padding: 40px 20px 20px 20px;
}

.home-offcanvas-modal {
  position: fixed;
  top: 0;
  left: -300px; /* Cambia el valor para ajustar la posición inicial */
  width: 300px;
  height: 100%;
  background-color: #fff;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2);
  transition: left 0.3s ease-in-out;
}

/* aqui termina el sidebar */

.home-mobile-sidebar-container-img {
  display: flex;
}

.home-mobile-sidebar-img {
  border-radius: 150px;
  width: 60px;
  height: 60px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.derecha {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
}

.home-mobile-sidebar-title-email{
  color: #000;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
}

.home-mobile-sidebar-title-user{
  color: #969292;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
}

.home-mobile-sidebar-sidebar-list{
  padding: 20px 0px 0px 0px;
}

.home-mobile-sidebar-links {
  text-align: initial;
  width: 100%;
  background-color:#ffffff; 
  color: #1E88E5;
  font-size: 18px;
  font-weight: 700;
  border: none;
  outline: none !important;
}

.home-mobile-sidebar-icon {
  margin-right: 5px;
  margin-bottom: 5px;
  width: 20px;
}

.home-mobile-sidebar-sidebar-logout{
    display: flex;
    height: 25vh;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}
  
.home-mobile-sidebar-btn {
  width: 80%;
  padding: 10px;
  font-weight: 700;
  border-radius: 10px;
  background: #DA3434;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

}

/* Finaliza Aplicacion movil */


