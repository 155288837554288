.pqrs-main{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.pqrs-container-form-two {
    width: 100%;
    min-height: 400px;
    background: #FFF;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.pqrs-container-card-two {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    position: relative;
}

.pqrs-input {
    font-size: 20px;
    font-weight: 500;
    font-family: 'Poppins';
}

.pqrs-container-input {
    width: 100%;
}

.pqrs-input {
    font-size: 20px;
    font-weight: 500;
    font-family: 'Poppins';
}