
.login-container-navbar {
    width: 100%;
    padding: 15px 5% 15px 5%;
    background-color: FFFFFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.login-navbar-flex-container {
    display: flex;
    justify-content: space-between;
}

.login-navbar-logo{
    width: 90px;
    height: 45px;
    cursor: pointer;
}

.login-navbar-tiltle{
    color: rgba(255, 0, 0, 0.70);
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    cursor: pointer;
}

/* Aplicacion movil */