.afp-update-card-main{
    display: flex;
    justify-content: center;
}

.afp-update-card {
    width: 100%;
    max-width: 1000px;
}

.afp-update-main {
    padding: 40px;
}

.afp-update-button {
    min-width: 150px;
}

.afp-update-title {
    font-size: 25px;
    font-weight: 600;
    font-family: 'Poppins';
    color: #6c757d;
}

.afp-update-input {
    font-size: 20px;
    font-weight: 500;
    font-family: 'Poppins';
}

.afp-update-img {
    width: 60px;
}

 /* Large devices (desktops, 992px and up) */
@media (max-width: 992px) { 
    .afp-update-main {
        padding: 20px;
    }
}