.simpleSlider-img {
    width: 200px;
    height: 130px;
}

.simpleSlider-img-foto2 {
    width: 280px;
    height: 100px;
}

.simpleSlider-img-foto9 {
    width: 480px;
    height: 100px;
}