.DefaultTable-main {
    width: 100%;
    padding: 20px 0px 0px 0px;
}

.DefaultTable-pagination-main {
    display: flex;
    justify-content: center;
}

.component-table-input {
    font-weight: 500;
    font-size: 20px;
    font-family: 'poppins';
}

.DefaultTable-header-th-width {
    min-width: 230px;
}

.DefaultTable-table-width{
    min-width: 180px;
}

.DefaultTable-check {
    position: relative !important;
}